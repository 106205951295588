<template>
  <div>

    <Row :gutter="8" class="mb-2">
      <i-col span="12">
        <div style="font-size: 14px">资产信息</div>
      </i-col>
        <i-col span="12">
          <a style="float: right; line-height: 30px" @click="handleRentSetting(-99)"
          >配置租金</a
        >
        </i-col>
          <i-col span="24">
            <Table :columns="datacolumns"  stripe size="small" :data="contractResources"></Table>
          </i-col>
        </Row>

        <row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <Button type="info" @click="handleNextStep">下一步</Button>
          </div>
        </i-col>
      </row>
      <rent-setting ref="rentSettingModal"></rent-setting>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import RentSetting from './RentSetting.vue'
import { toMoney } from '@/utils/wnumb_own'
import { listResourceAndFee } from '@/api/invest/contract'
/** new api */

export default {
  components: { RentSetting },
  props: {},
  data () {
    return {
      contractResources: [],
      datacolumns: [
        // {
        //   title: '资产编码',
        //   dataIndex: 'resourceName',
        //   key: 'resourceName',
        //   fixed: true
        // },
        { title: '内部编码', dataIndex: 'innerCode', key: 'innerCode' },
        { title: '房屋所有权证编号', dataIndex: 'certNo', key: 'certNo' },
        { title: '类别', dataIndex: 'resourceTypeName', key: 'resourceTypeName' },
        { title: '产权面积（㎡）', dataIndex: 'area', key: 'area' },
        {
          title: '计费周期',
          width: '200px',
          key: 'feeStartDate',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.startDate + '至' + item.endDate
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '签约面积（㎡）',
          key: 'feeSignArea',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.area
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费单价（元/㎡·⽉）',
          key: 'feeSignPrice',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.signPrice
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '月租金（元）',
          width: '160px',
          key: 'feeMonthPrice',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.monthPrice
              ))
            })

            return h('div', resultStr)
          }
        },

        { title: '交付日期', dataIndex: 'deliveryDate', key: 'deliveryDate' },
        {
          title: '操作',
          align: 'center',
          width: 120,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleRentSetting(params.row.id)
                  }
                }
              },
              '调整租金'
            )

            return h('div', [detailButton])
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    this.initlistResourceAndFee()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },

    initlistResourceAndFee () {
      const that = this
      listResourceAndFee({ contractId: that.contractInfo.id }).then((res) => {
        that.contractResources = res
      })
    },
    handleRentSetting (id) {
      this.$refs.rentSettingModal.showModal(this.contractResources, id)
    },
    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'basic')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {
      this.$store.commit('set_contract_currentStep', 'other')
      this.$store.commit('set_contract_update', new Date())
    }
  },
  watch: {
    beginUpdate () {
      this.initlistResourceAndFee()
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}
</script>
