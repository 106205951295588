<template>
  <div>
    <Row>
      <i-col span="24">
        <div class="mb-2">
          <strong>免租期</strong>
          <a class="text-blue-500" @click="visible=true">添加</a>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <div v-if="!freePeriodArray.length">本合同未约定免租期</div>
            <div v-else>本合同约定免租期如下：</div>
            <p v-for="(item, index) in freePeriodArray" :key="index">
              {{ index + 1 }}.
              <label v-if="item.type === 1"
                >{{ item.startDate }} 至 {{ item.endDate }}
              </label>
              <label v-if="item.type === 2"
                >自资产交付日起{{ item.duration }}个月</label
              >

              <a class="text-blue-500" @click="deletefreePeriod(item.id)">
                移除
              </a>
            </p>
          </i-col>
        </Row>
      </i-col>
      <i-col span="24" style="border-top: 1px dotted #ccc">
        <div class="mb-2">
          <strong>其他费用&结算方式</strong>
          <a class="text-blue-500" @click="editpaymentInfoModal=true">编辑</a>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <p>
              1.本合同⽣效之⽇，⼄⽅向甲⽅⽀付履约保证⾦{{
                contractInfo.depositAmount
              }}元（⼈⺠币{{ formatUpperMoney(contractInfo.depositAmount) }}）。
            </p>
            <p>
              2.乙方应同时按照每平方米{{
                contractInfo.propertyAmount
              }}
              元/㎡·月（人民币{{
                formatUpperMoney(contractInfo.propertyAmount)
              }}）向甲方另行支付运营管理费。
            </p>
            <p>
              3.⼄⽅每{{ contractInfo.paymentCycle }}个⽉结算⼀次，⼄⽅应在每
              {{ contractInfo.paymentCycle }} 个⽉期满
              {{ contractInfo.paymentAdvanceDay }} ⽇前向甲方⽀付结算金额。
            </p>
            <p v-if="contractInfo.paymentStartDate">
              4.首期租金（{{ contractInfo.paymentStartDate }}至{{
                contractInfo.paymentEndDate
              }}）⼄⽅应在本合同生效后
              {{ contractInfo.paymentAdvanceDay }} ⽇内一次性支付甲方。
            </p>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <Button type="info" @click="handleNextStep">下一步</Button>
          </div>
        </i-col>
      </row>
    <Modal v-model="visible">
      <template #header>
        <p>添加免租期</p>
      </template>
      <Form
        ref="freePeriodForm"
        :model="freePeriodFormModel"
        :rules="freePeriodFormModel.type === 1 ? rules : rules1"
        :label-width="120"
      >
        <FormItem
          prop="type"
          label="免租期类型"
          :rules="[{ required: true, message: '保证人类型不能为空' }]"
        >
          <RadioGroup v-model="freePeriodFormModel.type">
            <Radio v-for="item in typeArray" :key="item.id" :label="item.id">{{
              item.name
            }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          prop="freeStartDate"
          label="开始日期"
          v-if="freePeriodFormModel.type === 1"
        >
          <DatePicker
            v-model="freePeriodFormModel.freeStartDate"
            type="date"
            placeholder="开始日期"
            class="w-full"
          />
        </FormItem>
        <FormItem
          prop="freeEndDate"
          label="结束日期"
          v-if="freePeriodFormModel.type === 1"
        >
          <DatePicker
            v-model="freePeriodFormModel.freeEndDate"
            type="date"
            placeholder="结束日期"
            class="w-full"
          />
        </FormItem>
        <FormItem
          prop="duration"
          label="周期"
          v-if="freePeriodFormModel.type === 2"
        >
          <Input
            type="text"
            v-model="freePeriodFormModel.duration"
            placeholder="周期"
          >
            <template #append>
              <span>个⽉</span>
            </template></Input
          >
        </FormItem>
      </Form>
      <template #footer>
        <div class="text-right">
          <Button type="text" class="m-r-5" @click="visible = false"
        >关闭</Button
      >
      <Button type="primary" @click="handldSave">确定</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="editpaymentInfoModal">
      <template #header>
        <p>编辑其他费用&结算方式</p>
      </template>
      <Form
        ref="paymentInfoForm"
        :model="paymentInfoModel"
        :rules="paymentInfoRules"
        :label-width="150"
      >
        <FormItem prop="depositAmount" label="履约保证金">
          <Input
            type="text"
            v-model="paymentInfoModel.depositAmount"
            placeholder="履约保证金金额"
          >
            <template #append>
              <span>元</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="propertyAmount" label="运营管理费">
          <Input
            type="text"
            v-model="paymentInfoModel.propertyAmount"
            placeholder="运营管理费"
          >
            <template #append>
              <span>元/㎡·⽉</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentCycle" label="结算周期">
          <Input
            type="text"
            v-model="paymentInfoModel.paymentCycle"
            placeholder="结算周期"
          >
            <template #append>
              <span>⽉</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentAdvanceDay" label="提前付款天数">
          <Input
            type="text"
            v-model="paymentInfoModel.paymentAdvanceDay"
            placeholder="提前付款天数"
          >
            <template #append>
              <span>天</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentStartDate" label="首付周期开始时间">
          <DatePicker
            v-model="paymentInfoModel.paymentStartDate"
            type="date"
            placeholder="首付周期开始时间"
            class="w-full"
          />
        </FormItem>
        <FormItem prop="paymentEndDate" label="首付周期结束时间">
          <DatePicker
            v-model="paymentInfoModel.paymentEndDate"
            type="date"
            placeholder="首付周期结束时间"
            class="w-full"
          />
        </FormItem>
      </Form>
      <template #footer>
        <div class="text-right">
          <Button type="text" class="m-r-5" @click="editpaymentInfoModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handldPaymentInfoSave">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script >

import { parseUpperMoney } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'

import {
  listResourceAndFee, listFreePeriod, addFreePeriod, savePayAmount, savePayCycle, deleteFreePeriod
} from '@/api/invest/contract'

export default {
  components: { },
  props: {},
  data () {
    return {
      freePeriodArray: [],
      visible: false,
      editpaymentInfoModal: false,
      typeArray: [
        { id: 1, name: '固定日期' },
        { id: 2, name: '固定周期' }
      ],
      paymentInfoModel: {
        contractId: 0,
        propertyAmount: 15,
        depositAmount: 0,
        paymentEndDate: '',
        paymentStartDate: '',
        paymentCycle: 1,
        paymentAdvanceDay: 5
      },
      rules: {
        type: [{ required: true, message: '请选择免租期类型' }],
        freeStartDate: [{ required: true, message: '请选择免租期开始时间' }],
        freeEndDate: [{ required: true, message: '请选择免租期结束时间' }]
      },
      rules1: {
        type: [{ required: true, message: '请选择免租期类型' }],
        duration: [{ required: true, message: '请输入免租期周期' }]
      },
      paymentInfoRules: {
        depositAmount: [{ required: true, message: '请输入履约保证金' }],
        propertyAmount: [{ required: true, message: '请输入运营管理费' }],
        paymentCycle: [{ required: true, message: '请输入结算周期' }],
        paymentAdvanceDay: [{ required: true, message: '请输入提前付款天数' }]
      },
      freePeriodFormModel: {
        contractId: 0,
        type: 1,
        freeStartDate: '',
        freeEndDate: '',
        duration: undefined
      }
    }
  },
  mounted () {},
  created () {
    this.initFreePeriodList()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (money) {
      return parseUpperMoney(money)
    },

    initFreePeriodList () {
      listFreePeriod({ contractId: this.contractInfo.id }).then((res) => {
        this.freePeriodArray = res
      })
    },
    handldSave () {
      this.$refs.freePeriodForm.validate((valid) => {
        if (valid) {
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '请确认合同基本信息是否填写完整？',
          //   onOk: () => {

          //   }
          // })
          this.freePeriodFormModel.contractId = this.contractInfo.id

          this.freePeriodFormModel.freeStartDate = ParseDate(
            this.freePeriodFormModel.freeStartDate
          )
          this.freePeriodFormModel.freeEndDate = ParseDate(this.freePeriodFormModel.freeEndDate)

          addFreePeriod(this.freePeriodFormModel).then((res) => {
            this.visible = false
            this.initFreePeriodList()
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },
    deletefreePeriod (fId) {
      this.$Modal.confirm({
        title: '删除确认',
        content: '是否删除当前免租期？',
        onOk: () => {
          deleteFreePeriod({ freeId: fId }).then((res) => {
            this.initFreePeriodList()
          })
        }
      })
    },
    handldPaymentInfoSave () {
      this.$refs.paymentInfoForm.validate((valid) => {
        if (valid) {
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '请确认合同基本信息是否填写完整？',
          //   onOk: () => {

          //   }
          // })
          savePayAmount({
            contractId: this.contractInfo.id,
            depositAmount: this.paymentInfoModel.depositAmount,
            propertyAmount: this.paymentInfoModel.propertyAmount
          }).then((res) => {
            if (res) {
              savePayCycle({
                contractId: this.contractInfo.id,
                paymentCycle: this.paymentInfoModel.paymentCycle,
                paymentAdvanceDay: this.paymentInfoModel.paymentAdvanceDay,
                paymentStartDate: this.paymentInfoModel.paymentStartDate
                  ? ParseDate(this.paymentInfoModel.paymentStartDate)
                  : '',
                paymentEndDate: this.paymentInfoModel.paymentEndDate
                  ? ParseDate(this.paymentInfoModel.paymentEndDate)
                  : ''
              }).then((result) => {
                if (result) {
                  this.editpaymentInfoModal = false
                  this.$store.commit('set_contract_update', new Date())
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },
    initlistResourceAndFee () {
      const that = this
      listResourceAndFee({ contractId: that.contractInfo.id }).then((res) => {
        that.contractResources = res
      })
    },
    handleRentSetting (id) {
      this.$refs.rentSettingModal.showModal(this.contractResources, id)
    },

    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'price')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {
      this.$store.commit('set_contract_currentStep', 'files')
      this.$store.commit('set_contract_update', new Date())
    }
  },
  watch: {
    beginUpdate () {
      this.initlistResourceAndFee()
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}

</script>
