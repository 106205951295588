<template>
  <div>
    <detail></detail>
        <row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <Button type="info" @click="confirmContract">提交合同</Button>
          </div>
        </i-col>
      </row>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import detail from '@/components/investmentContract/modalComponents/detail.vue'
/** new api */

import { submitContract } from '@/api/invest/contract'

export default {
  components: { detail },
  props: {},
  data () {
    return {

    }
  },
  mounted () {},
  created () {
  },
  methods: {
    confirmContract () {
      this.$Modal.confirm({
        title: '操作确认',
        content:
          '确认提交合同？',
        onOk: () => {
          submitContract({ contractId: this.contractInfo.id }).then((res) => {
            this.$store.commit('set_contract_pageType', 'list')
            // this.$store.commit('set_contract_currentStep', 'other')
            this.$store.commit('set_contract_update', new Date())
          })
        }
      })
    },
    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'files')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {

    }
  },
  watch: {
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    }
  }
}
</script>
