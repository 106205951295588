<template>
  <div>
    <Row class="p-b-10">
      <i-col span="24">
        <span class="workplatform-title">{{ contractInfo.name }}</span>
        <span class="m-l-10 workplatform-title">{{ contractInfo.code }}</span>
        <a class="text-blue-500" @click="changeContractCode">自定义合同编码</a>
        <!-- <Tag class="m-l-5" color="green" v-if="!showDisCount">
          {{contract.contractCategoryName}}
        </Tag>
        <Tag
          class="m-l-5"
          color="blue"
          v-else-if="contract.contractCategory === 2"
          >{{contract.contractCategoryName}}</Tag
        >
        <Tag class="m-l-5" color="blue" v-else-if="contract.type === 3"
          >代理商合同</Tag
        > -->
      </i-col>
    </Row>
    <Row class="p-b-10" v-if="contractInfo.id">
      <!-- <i-col span="24">
        <span  class="title"> 发布类型 </span>
        <span>{{ contract.contractCategoryName||'无' }}</span>
      </i-col> -->
      <!-- <i-col span="24" v-if="contract.salePolicy===2">
        <span  class="title"> 发布标记 </span>
        <span>{{ contract.salePolicyName }}</span>
      </i-col> -->
      <i-col span="24">
        <span class="title"> 甲方 </span>
        <span>{{ contractInfo.firstParty.partyName || "无" }}</span>
      </i-col>
      <i-col span="8"
        ><span class="title">法定代表人 </span
        >{{ contractInfo.firstParty.partyLegalName }}</i-col
      >
      <i-col span="8"
        ><span class="title"
          >{{
            contractInfo.firstParty.partyType === 10
              ? "社会统一信用代码"
              : "身份证号"
          }} </span
        >{{ contractInfo.firstParty.partyOrgCode }}</i-col
      >
      <i-col span="8"
        ><span class="title">地址 </span
        >{{ contractInfo.firstParty.partyAddress }}</i-col
      >
      <i-col span="24">
        <span class="title">乙方 </span>
        <span>{{ contractInfo.secondParty.partyName }}</span>
      </i-col>
      <i-col span="8"
        ><span class="title">法定代表人 </span
        >{{ contractInfo.secondParty.partyLegalName }}</i-col
      >
      <i-col span="8"
        ><span class="title"
          >{{
            contractInfo.secondParty.partyType === 10
              ? "社会统一信用代码"
              : "身份证号"
          }} </span
        >{{ contractInfo.secondParty.partyOrgCode }}</i-col
      >
      <i-col span="8"
        ><span class="title">地址 </span
        >{{ contractInfo.secondParty.partyAddress }}</i-col
      >
    </Row>
    <div style="font-size: 14px">合同日期</div>
    <Row :gutter="8" class="mb-2">
      <i-col span="24">
        <Form
          ref="contractDateSettingForm"
          :model="contractDateSettingModel"
          :rules="rules"
          :label-width="120"
        >
          <Row :gutter="8">
            <i-col span="6">
              <FormItem prop="startDate" label="合同开始日期">
                <DatePicker
                  v-model="contractDateSettingModel.startDate"
                  type="date"
                  placeholder="合同开始日期"
                  class="w-full"
                />
              </FormItem>
            </i-col>

            <i-col span="6">
              <FormItem prop="endDate" label="合同结束日期">
                <DatePicker
                  v-model="contractDateSettingModel.endDate"
                  type="date"
                  placeholder="合同结束日期"
                  class="w-full"
                />
              </FormItem>
            </i-col>
            <i-col span="6">
              <FormItem prop="businessDate" label="最晚运营时间">
                <DatePicker
                  v-model="contractDateSettingModel.businessDate"
                  type="date"
                  placeholder="最晚运营时间"
                  class="w-full"
                />
              </FormItem>
            </i-col>
            <i-col span="6">
              <FormItem prop="signDate" label="合同签订日期">
                <DatePicker
                  v-model="contractDateSettingModel.signDate"
                  type="date"
                  placeholder="合同签订日期"
                  class="w-full"
                />
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </i-col>
    </Row>
    <div style="font-size: 14px">合同附加条款</div>
    <Row :gutter="8">
      <i-col span="24">
        <Input
          v-model="contractDateSettingModel.description"
          type="textarea"
          :rows="5"
          placeholder="合同附加条款"
        />
      </i-col>
    </Row>

    <row class="p-b-10 p-t-10">
      <i-col span="24" class="text-right">
        <Button type="info" @click="handleNextStep">下一步</Button>
      </i-col>
    </row>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { updateContractCode, addContractDate } from '@/api/invest/contract'
/** new api */

export default {
  components: {},
  props: {},
  data () {
    return {
      contractCode: '',
      contractDateSettingModel: {
        contractId: 0,
        signDate: '',
        startDate: '',
        endDate: '',
        businessDate: ''
      },
      rules: {
        startDate: [{ required: true, message: '请选择合同开始时间' }],
        endDate: [{ required: true, message: '请选择合同结束时间' }]
      }
    }
  },
  mounted () {},
  created () {
    this.contractDateSettingModel.startDate = this.contractInfo.startDate
    this.contractDateSettingModel.signDate = this.contractInfo.signDate
    this.contractDateSettingModel.endDate = this.contractInfo.endDate
    this.contractDateSettingModel.businessDate = this.contractInfo.businessDate
    this.contractDateSettingModel.description = this.contractInfo.description
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    changeContractCode () {
      this.contractCode = this.contractInfo.code
      this.$Modal.confirm({
        title: '自定义合同编号',
        render: (h) => {
          return h('Input', {
            props: {
              value: this.contractCode,
              autofocus: true,
              placeholder: '请填写新的合同编号'
            },
            on: {
              input: (val) => {
                this.contractCode = val
              }
            }
          })
        },
        loading: true,
        onOk: () => {
          updateContractCode({
            code: this.contractCode,
            contractId: this.contractInfo.id
          })
            .then((res) => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '自定义合同编号完成！' })
                this.$store.commit('set_contract_update', new Date())
              }
              this.$Modal.remove()
            })
            .catch((error) => {
              this.$Modal.remove()
              this.$Notice.error({
                title: '服务器请求错误',
                desc: error
              })
            })
        }
      })
    },
    handleNextStep () {
      this.$refs.contractDateSettingForm.validate((valid) => {
        if (valid) {
          if (this.contractDateSettingModel.startDate) {
            this.contractDateSettingModel.startDate = ParseDate(
              this.contractDateSettingModel.startDate
            )
          }
          if (this.contractDateSettingModel.endDate) {
            this.contractDateSettingModel.endDate = ParseDate(
              this.contractDateSettingModel.endDate
            )
          }
          if (this.contractDateSettingModel.businessDate) {
            this.contractDateSettingModel.businessDate = ParseDate(
              this.contractDateSettingModel.businessDate
            )
          }
          if (this.contractDateSettingModel.signDate) {
            this.contractDateSettingModel.signDate = ParseDate(
              this.contractDateSettingModel.signDate
            )
          }
          this.contractDateSettingModel.contractId = this.contractInfo.id
          addContractDate(this.contractDateSettingModel).then((res) => {
            this.$store.commit('set_contract_currentStep', 'price')
            this.$store.commit('set_contract_update', new Date())
          })
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '请确认合同基本信息是否填写完整？',
          //   onOk: () => {

          //   }
          // })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    }
  },
  watch: {
    contractInfo () {
      this.contractDateSettingModel.startDate = this.contractInfo.startDate
      this.contractDateSettingModel.signDate = this.contractInfo.signDate
      this.contractDateSettingModel.endDate = this.contractInfo.endDate
      this.contractDateSettingModel.businessDate =
        this.contractInfo.businessDate
      this.contractDateSettingModel.description = this.contractInfo.description
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    }
  }
}
</script>
