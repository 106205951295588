<template>
  <div>
    <Row>
      <i-col span="24">
        <div class="mb-2">
          <strong>合同附件</strong>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <RadioGroup v-model="fileType" type="button" button-style="solid">
              <Radio
                v-for="item in contractfiletypes"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</Radio
              >
            </RadioGroup>
          </i-col>
          <i-col span="24">
            <Upload
              ref="upload"
              :with-credentials="true"
              :action="actionUrl"
              :data="data"
              :headers="uploadHeaders"
              :show-upload-list="false"
              :format="format"
              :max-size="maxSize"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleExceedMaxsize"
              multiple
              :on-success="handleFileUploadSuccess"
              type="drag"
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>单击或拖动文件到此区域进行上传</p>
              </div>
            </Upload>
          </i-col>
          <i-col span="24">
            <Table :columns="fileColumns"  stripe size="small" :data="fileList">
              <template #operation="{ row }">
                <a class="ml-1" @click="handleDownload(row)">下载</a>
                <a class="ml-1 text-red-500" @click="deleteFileConfirm(row)"
                  >删除</a
                >
              </template>
            </Table>
          </i-col>
        </Row>
      </i-col>
    </Row>

    <Row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <Button type="info" @click="handleNextStep">下一步</Button>
          </div>
        </i-col>
      </Row>
    <Modal
      v-if="viewFileModal"
      width="70%"
      v-model="viewFileModal"
      :footer="null"
    >
      <template #title>
        <p class="text-center">{{ previewFile.fileName }}</p>
      </template>
      <a-spin :spinning="fileLoading" tip="文件处理中...">
        <preview-docx
          v-if="
            previewFile.mimeType ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          "
          ref="refPreviewDocx"
        ></preview-docx>
        <iframe
          v-else-if="previewFile.mimeType === 'application/pdf'"
          :src="pdfUrl"
          height="700"
          width="100%"
        ></iframe>
        <div v-else class="text-center py-2 font-bold text-lg">
          <span>当前文件暂不支持预览</span>
        </div>
      </a-spin>
    </Modal>
  </div>
</template>
<script >

import { downloadFileRequest } from '@/utils/download'
import { parseUpperMoney } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'

import { signurlByAttach, removeFileAttach } from '@/api/resource/endpoint'
import { listDictLabel } from '@/api/contract/dict'
import {
  getFileList, deleteFile, uploadFile
} from '@/api/invest/file'

import { getStorage } from '@/utils/storage'
export default {
  components: { },
  props: {},
  data () {
    return {
      contractfiletypes: [],
      fileType: 0,
      fileList: [],

      data: { code: 0 },
      actionUrl: process.env.VUE_APP_API_URL_V2 + '/ooh-resource/oss/endpoint/put-file-attach',
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      format: ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'],
      maxSize: 204800,
      uploadFiles: [],
      fileColumns: [
        { title: '文件名称', dataIndex: 'fileName', key: 'fileName' },
        { title: '文件分类', dataIndex: 'fileTypeName', key: 'fileTypeName' },
        { title: '文件类型', dataIndex: 'mimeType', key: 'mimeType' },
        // { title: '文件大小', dataIndex: 'length', key: 'length' },
        { title: '上传时间', dataIndex: 'createTime', key: 'createTime' },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleDownload(params.row)
                  }
                }
              },
              '下载'
            )

            const removeButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要删除附件：【 ' +
                        params.row.fileName +
                        '】？</p>',
                      loading: true,
                      onOk: () => {
                        this.handleRemove(params.row)
                        this.$Modal.remove()
                      }
                    })
                  }
                }
              },
              '删除'
            )
            const returnBtns = []

            returnBtns.push(detailButton)
            returnBtns.push(removeButton)
            return h('div', returnBtns)
          }
        }
      ],

      viewFileModal: false
    }
  },
  mounted () {},
  created () {
    this.initFileTypes()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (money) {
      return parseUpperMoney(money)
    },

    initFileTypes () {
      // 文件类型
      listDictLabel({ dictType: 'contract_file_type' }).then((res) => {
        this.contractfiletypes = res.map((item) => {
          return { id: item.id, name: item.name }
        })
        this.fileType = this.contractfiletypes[0].id
        this.reloadFileList()
      })
    },
    reloadFileList () {
      const query = {
        category: 2,
        keyId: this.contractInfo.id
      }
      if (this.fileType > 0) {
        query.fileType = this.fileType
      }
      getFileList(query).then((res) => {
        this.fileList = res
      })
    },

    handleExceedMaxsize (file) {
      this.$Notice.warning({
        title: '已经超过了文件大小限制',
        desc: '文件  ' + file.name + ' 过大，不能超过' + this.maxSize + 'Kb'
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件类型不正确',
        desc: '文件  ' + file.name + ' 的类型只能是' + this.format.toString()
      })
    },
    handleFileUploadSuccess (response, file) {
      if (response.success) {
        uploadFile({
          category: 2,
          fileType: this.fileType,
          keyId: this.contractInfo.id,
          fileList: JSON.stringify(
            [{ fileName: file.response.data.originalName, filePath: file.response.data.id, mimeType: file.response.data.mimeType }]
          )
        }).then(res => {
          if (res.success) {
            this.reloadFileList()
          }
        })

        // this.uploadFiles.push(file)
      }
    },
    handleDownload (file) {
      signurlByAttach({ attachId: file.filePath }).then(res => {
        downloadFileRequest(res.data, { }, file.fileName)
      })
    },
    handleRemove (file) {
      removeFileAttach({ attachId: file.filePath }).then(() => {
        deleteFile({ fileId: file.id }).then(res => {
          this.reloadFileList()
        })
      })
    },
    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'other')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {
      this.$store.commit('set_contract_currentStep', 'detail')
      this.$store.commit('set_contract_update', new Date())
    }
  },
  watch: {
    beginUpdate () {
      this.initlistResourceAndFee()
    },
    fileType () {
      this.reloadFileList()
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}

</script>
